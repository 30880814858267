import { ReactNode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouteList } from "~/src/router/config";

function Router(): ReactNode {
  return (
    <BrowserRouter>
      <Routes>
        {RouteList.map((route, index) => (
          <Route key={`BrowserRouteItem${index}`} {...route} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
