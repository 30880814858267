import { css } from "@emotion/react";

const globalStyle = css`
  html,
  body {
    margin: 0;
    padding: 0;
  }
`;

export default globalStyle;
