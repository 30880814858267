import { ReactNode } from "react";
import Head from "~/src/components/layouts/Head";
import { ServerInformation } from "~/src/configs/server";
import { ProjectHistory } from "~/src/constants";

function MainPage(): ReactNode {
  return (
    <>
      <Head>
        <title>불타는 이 공간</title>
      </Head>
      <div>불타는 이 공간</div>
      <div>완전 변경!</div>
      Connected : {JSON.stringify(ServerInformation)}
      <hr />
      {JSON.stringify(ProjectHistory)}
    </>
  );
}

export default MainPage;
