import { Global } from "@emotion/react";
import { createRoot } from "react-dom/client";
import Router from "~/src/router/Router";
import globalStyle from "~/src/styles";

window.onload = function mountApplication() {
  const root = createRoot(
    window.document.getElementById("root") as HTMLDivElement
  );
  root.render(
    <>
      <Global styles={globalStyle} />
      <Router />
    </>
  );
};

// @todo: lftp diff 정밀 비교