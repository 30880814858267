import { RouteProps } from "react-router-dom";
import { PathMap } from "~/src/constants/path";
import MainPage from "~/src/pages/Main.page";
import NotFoundPage from "~/src/pages/NotFound.page";

export const RouteNotFound: RouteProps = {
  path: PathMap.ALL,
  Component: NotFoundPage,
} as const;

export const RouteMainPage: RouteProps = {
  path: PathMap.MAIN,
  Component: MainPage,
} as const;

export const RouteList = [RouteMainPage, RouteNotFound] as const;
