declare global {
  interface Window {
    _connection?: {
      result: boolean;
      date: number;
      message?: string;
    };
  }
}

export const ServerInformation = window._connection;
delete window._connection;
